<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' },
                { link: 'CheckoutShipping', name: 'Szállítási mód' },
                { link: 'CheckoutPayment', name: 'Fizetési mód' }
            ]"
        />
        <section class="listSection listing">
            <div class="container">
                <div id="pages">
                    <div class="headline">
                        <h1>Szállítási adatok</h1>
                        <hr />
                    </div>
                    <CheckoutBreadcrumbs :step="4" />
                    <div class="row">
                        <div class="col-12">
                            <form v-on:submit.prevent="setPayment">
                                <div class="my-4 radio-corrector">
                                    <template v-for="(item, index) in model">
                                        <label
                                            class="d-block mb-3"
                                            v-bind:key="'payment-' + index"
                                        >
                                            <input
                                                type="radio"
                                                name="payment"
                                                :value="item.id"
                                                v-model="form.payment"
                                                v-bind:checked="index === 0"
                                            />
                                            {{ item.name }}
                                            <template
                                                v-if="
                                                    parseInt(item.price) !== 0
                                                "
                                            >
                                                <!--({{ item.price }} Ft)-->
                                            </template>
                                        </label>
                                    </template>
                                </div>
                                <div class="my-4">
                                    <label>Kuponkód megadása</label>
                                    <input
                                        type="text"
                                        name="coupon"
                                        v-model="form.coupon"
                                        class="form-control"
                                    />
                                </div>

                                <div class="row">
                                    <div class="col-md-8">
                                        <router-link
                                            :to="'/'"
                                            class="d-block continue-shop-btn transition"
                                            >Vásárlás folytatása
                                        </router-link>
                                    </div>
                                    <div class="col-md-4 text-end">
                                        <button
                                            type="submit"
                                            class="btn btn-primary w-100 mb-2"
                                        >
                                            Tovább
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CheckoutBreadcrumbs from "@/components/CheckoutBreadcrumbs";

export default {
    name: "CheckoutPayment",
    components: { CheckoutBreadcrumbs, BreadCrumbs },
    data() {
        return {
            model: {},
            form: {
                payment: null,
                coupon: null
            },
            shipping: null
        };
    },
    beforeMount() {
        if (!$cookies.get("billing")) {
            this.$router.push({ name: "Cart" });
        }
        if (!$cookies.get("shipping")) {
            this.$router.push({ name: "CheckoutShipping" });
        }

        this.shipping = parseInt($cookies.get("shipping"));
        this.getPayments();
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        },
        dateNow: function() {
            let yourDate = new Date();
            return yourDate.toISOString().split("T")[0];
        }
    },
    methods: {
        getPayments() {
            ApiService.get(`${this.url}/payment/get`)
                .then(response => {
                    this.model = response.data.data;
                    this.filterPayments();
                    this.form.payment = this.model[0].id;
                })
                .finally(() => {
                    if ($cookies.get("payment")) {
                        this.form.payment = $cookies.get("payment");
                    }
                });
        },
        filterPayments() {
            let i;
            switch (this.shipping) {
                case 1:
                    //Házhozszállítás
                    i = parseInt(this.model.length) - 1;
                    while (typeof this.model[i] !== "undefined") {
                        if (this.model[i].id === 3 || this.model[i].id === 4) {
                            this.model.splice(i, 1);
                        }
                        i--;
                    }
                    break;
                case 2:
                    //Személyes átvétel kiskőrösön
                    i = parseInt(this.model.length) - 1;
                    while (typeof this.model[i] !== "undefined") {
                        if (this.model[i].id === 1 || this.model[i].id === 4) {
                            this.model.splice(i, 1);
                        }
                        i--;
                    }
                    break;
                case 3:
                    //Személyes átvétel kecelen
                    i = parseInt(this.model.length) - 1;
                    while (typeof this.model[i] !== "undefined") {
                        if (this.model[i].id === 1 || this.model[i].id === 4) {
                            this.model.splice(i, 1);
                        }
                        i--;
                    }
                    break;
                case 4:
                    i = parseInt(this.model.length) - 1;
                    while (typeof this.model[i] !== "undefined") {
                        if (this.model[i].id === 1 || this.model[i].id === 3) {
                            this.model.splice(i, 1);
                        }
                        i--;
                    }
                    //Személyes átvétel törökbálinton
                    break;
            }
        },
        setPayment() {
            const cartUuid = $cookies.get("cart_uuid");
            const self = this;
            ApiService.post(`${self.url}/validate-coupon`, {
                code: self.form.coupon
            }).then(() => {
                ApiService.post(
                    `${self.url}/cart/payment?uuid=${cartUuid}&payment=${this.form.payment}`
                ).then(() => {
                    $cookies.set("payment", self.form.payment, 86400);
                    self.$router.push({ name: "CheckoutFinish" });
                });
            });
        }
    }
};
</script>

<style scoped></style>
