<template>
    <div class="checkoutline first">
        <router-link
            tag="div"
            :to="{ name: 'Cart' }"
            v-bind:class="{ activeStep: step === 1, beforeActive: step > 1 }"
        >
            <div class="step transition">1</div>
            <span class="stepTitle">Kosár összesítő</span>
        </router-link>
        <router-link
            tag="div"
            :to="{ name: 'CheckoutBilling' }"
            v-bind:class="{ activeStep: step === 2, beforeActive: step > 2 }"
        >
            <div class="step transition">2</div>
            <span class="stepTitle">Számlázási adatok</span>
        </router-link>
        <router-link
            tag="div"
            :to="{ name: 'CheckoutShipping' }"
            v-bind:class="{ activeStep: step === 3, beforeActive: step > 3 }"
        >
            <div class="step transition">3</div>
            <span class="stepTitle">Szállítási mód</span>
        </router-link>
        <router-link
            tag="div"
            :to="{ name: 'CheckoutPayment' }"
            v-bind:class="{ activeStep: step === 4, beforeActive: step > 4 }"
        >
            <div class="step transition">4</div>
            <span class="stepTitle">Fizetési mód</span>
        </router-link>
        <router-link
            tag="div"
            :to="{ name: 'CheckoutFinish' }"
            v-bind:class="{ activeStep: step === 5, beforeActive: step > 5 }"
        >
            <div class="step transition">5</div>
            <span class="stepTitle">Összegzés</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "CheckoutBreadcrumbs",
    props: ["step"]
};
</script>

<style scoped></style>
