var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('BreadCrumbs',{attrs:{"elements":[
            { link: 'Cart', name: 'Kosár' },
            { link: 'CheckoutBilling', name: 'Számlázási adatok' },
            { link: 'CheckoutShipping', name: 'Szállítási mód' },
            { link: 'CheckoutPayment', name: 'Fizetési mód' },
            { link: 'CheckoutFinish', name: 'Rendelés áttekintése' }
        ]}}),_c('section',{staticClass:"listSection listing"},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"pages"}},[_vm._m(0),_c('CheckoutBreadcrumbs',{attrs:{"step":5}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(1),_vm._l((_vm.cart.items),function(item,index){return [_c('div',{key:'cart-' + index,staticClass:"row align-items-center border-bottom py-3"},[_c('div',{staticClass:"col-md-6"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.product.cicsopnev)+" "),_c('br'),_vm._v(" "+_vm._s(item.product.cikknev)+" "),_c('br'),_vm._v(" "+_vm._s(item.product.m1nev))])]),_c('div',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(item.product .calculated_special_price)+" "),(
                                            !item.product.calculated_special_price.includes(
                                                'Ft'
                                            )
                                        )?[_vm._v("Ft")]:_vm._e()],2),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.quantity),expression:"item.quantity"}],staticClass:"form-control",attrs:{"type":"number","readonly":"","min":"1","max":item.product.tat},domProps:{"value":(item.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "quantity", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(parseInt( item.product.calculated_special_price.replace( " ", "" ) ) * item.quantity)+" Ft ")])])]})],2),_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-xl-9"},[_c('label',{staticClass:"d-block"},[_vm._v("Megjegyzés (max. 45 karakter)")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.cart.notes),expression:"cart.notes"}],attrs:{"rows":"5","maxlength":"45"},domProps:{"value":(_vm.cart.notes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cart, "notes", $event.target.value)}}})]),(_vm.cart && _vm.cart.cart_total)?_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Abroncs végösszeg: ")]),_c('div',{staticClass:"col-6 text-end"},[_vm._v(" "+_vm._s(_vm.cart.cart_total.total_f)+" Ft ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Szállítási költség: ")]),_c('div',{staticClass:"col-6 text-end"},[_vm._v(" "+_vm._s(_vm.cart.cart_total.shipping_f)+" Ft ")])]),(
                                            _vm.cart.cart_total.total_payment >
                                                0
                                        )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Utánvét összege: ")]),_c('div',{staticClass:"col-6 text-end"},[_vm._v(" "+_vm._s(_vm.cart.cart_total .total_payment_f)+" Ft ")])]):_vm._e(),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-6 text-end"},[_c('strong',{staticClass:"text-orange"},[_vm._v(_vm._s(_vm.cart.cart_total .total_with_shipping_f)+" Ft")])])])]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('label',{staticClass:"d-block mb-2 aszf"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.aszf),expression:"aszf"}],attrs:{"type":"checkbox","name":"aszf","value":"1"},domProps:{"checked":Array.isArray(_vm.aszf)?_vm._i(_vm.aszf,"1")>-1:(_vm.aszf)},on:{"change":function($event){var $$a=_vm.aszf,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.aszf=$$a.concat([$$v]))}else{$$i>-1&&(_vm.aszf=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.aszf=$$c}}}}),_vm._v(" Elfogadom az "),_c('a',{staticStyle:{"text-decoration":"underline !important"},attrs:{"href":"/adatvedelmi-tajekoztato","target":"_blank"}},[_vm._v("Adatvédelmi nyilatkozatot")])])])]),_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-xl-3 offset-xl-9"},[_c('button',{staticClass:"btn btn-primary w-100 my-4",attrs:{"type":"submit","disabled":_vm.orderDisabled},on:{"click":_vm.doOrder}},[_vm._v(" Megrendelem ")])])])])])])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Szállítási adatok")]),_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-center border-bottom pb-3 d-none d-md-flex"},[_c('div',{staticClass:"col-md-6"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v("Termék neve")])]),_c('div',{staticClass:"col-md-2"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v("Ár/Db")])]),_c('div',{staticClass:"col-md-2"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v("Mennyiség")])]),_c('div',{staticClass:"col-md-2"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v("Részösszeg")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6"},[_c('strong',{staticClass:"text-orange"},[_vm._v("Végösszeg:")])])}]

export { render, staticRenderFns }