var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('BreadCrumbs',{attrs:{"elements":[
            { link: 'Cart', name: 'Kosár' },
            { link: 'CheckoutBilling', name: 'Számlázási adatok' },
            { link: 'CheckoutShipping', name: 'Szállítási mód' }
        ]}}),_c('section',{staticClass:"listSection listing"},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"pages"}},[_vm._m(0),_c('CheckoutBreadcrumbs',{attrs:{"step":3}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}}},[_c('div',{staticClass:"my-4 radio-corrector"},[_vm._l((_vm.model),function(item,index){return _c('label',{key:'shipping-' + index,staticClass:"d-block mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.shipping),expression:"form.shipping"}],attrs:{"required":"","type":"radio","name":"shipping"},domProps:{"value":item.id,"checked":index === 0,"checked":_vm._q(_vm.form.shipping,item.id)},on:{"change":function($event){return _vm.$set(_vm.form, "shipping", item.id)}}}),_vm._v(" "+_vm._s(item.name)+" "),(parseInt(item.price) === 0)?[_vm._v(" Ingyenes ")]:_vm._e(),(
                                            item.discount_from !== null &&
                                                item.discount_to !== null &&
                                                item.discount_from !==
                                                    '0000-00-00 00:00:00' &&
                                                item.discount_to !==
                                                    '0000-00-00 00:00:00' &&
                                                item.discount_from <=
                                                    _vm.dateNow &&
                                                item.discount_to >= _vm.dateNow
                                        )?[_vm._v(" ("+_vm._s(item.discount_price)+" Ft / db) ")]:[(
                                                parseInt(item.price_1) >
                                                    0 ||
                                                    parseInt(item.price) > 0
                                            )?[_vm._v(" ( "),(
                                                    parseInt(item.price_1) >
                                                        0
                                                )?[_vm._v(" 1 DB VÁSÁRLÁSA ESETÉN: "+_vm._s(item.price_1_formatted)+" / DB ; ")]:_vm._e(),(
                                                    parseInt(item.price) > 0
                                                )?[_vm._v(" 2 VAGY TÖBB GUMIABRONCS VÁSÁRLÁSAKOR "+_vm._s(item.price_formatted)+" / DB ")]:_vm._e(),_vm._v(" ) ")]:_vm._e()],_c('br'),(item.description)?_c('small',[_vm._v(" ( "+_vm._s(item.description)+" ) ")]):_vm._e()],2)}),(
                                        _vm.form.shipping && _vm.form.shipping === 4
                                    )?_c('div',{staticClass:"alert alert-danger",staticStyle:{"margin-top":"10px"},attrs:{"role":"alert"}},[_c('strong',[_vm._v("Kérjük vegye figyelembe, hogy ennél a szállítási módnál csak bankkártyával és előreutalással tud fizetni!")])]):_vm._e()],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('router-link',{staticClass:"d-block continue-shop-btn transition",attrs:{"to":'/'}},[_vm._v("Vásárlás folytatása ")])],1),_vm._m(1)])])])])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Szállítási adatok")]),_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4 text-end"},[_c('button',{staticClass:"btn btn-primary w-100 mb-2",attrs:{"type":"submit"}},[_vm._v(" Tovább ")])])}]

export { render, staticRenderFns }