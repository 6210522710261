<template>
    <div class="content cart-checkout-container">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' }
            ]"
        />
        <section class="listSection listing">
            <div class="container">
                <div id="pages">
                    <div class="headline">
                        <h1>Számlázási adatok</h1>
                        <hr />
                    </div>
                    <CheckoutBreadcrumbs :step="2" />
                    <div class="row">
                        <div class="col-12">
                            <form
                                v-on:submit.prevent="validateData"
                                class="billingform"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mt-4 radio-corrector">
                                            <label class="d-block">
                                                <input
                                                    type="radio"
                                                    name="usertype"
                                                    v-on:click="
                                                        user_without_vat
                                                    "
                                                    checked
                                                />
                                                Magánszemélyként vásárolok
                                            </label>
                                            <label class="d-block">
                                                <input
                                                    type="radio"
                                                    name="usertype"
                                                    v-on:click="user_with_vat"
                                                />
                                                Adószámos magánszemélyként
                                                vásárolok
                                            </label>
                                            <label class="d-block">
                                                <input
                                                    type="radio"
                                                    name="usertype"
                                                    v-on:click="is_company"
                                                />
                                                Cégként vásárolok
                                            </label>
                                            <p
                                                v-if="
                                                    isCompany ||
                                                        customer_with_vat
                                                "
                                                class="h6 text-danger"
                                            >
                                                Kérjük ügyeljen a számlázási és
                                                szállítási adatok helyes
                                                kitöltésére!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div v-if="!isCompany" class="mt-4">
                                            <label>Név*</label>
                                            <input
                                                type="text"
                                                name="name"
                                                v-model="model.billing.name"
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.name'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.name'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                        <div class="mt-4" v-if="isCompany">
                                            <label>Cégnév*</label>
                                            <input
                                                type="text"
                                                name="company_name"
                                                v-model="model.billing.name"
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.name'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.name'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <label
                                                >E-mail*
                                                <small>
                                                    (pl.:
                                                    toth.janos@gmail.com)</small
                                                ></label
                                            >
                                            <input
                                                type="email"
                                                name="email"
                                                v-model="model.email"
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'email'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'email'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <template
                                                v-if="
                                                    isCompany ||
                                                        customer_with_vat
                                                "
                                            >
                                                <label
                                                    >Adószám*
                                                    <small>
                                                        (pl.:
                                                        23452385-1-12)</small
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    name="vat_number"
                                                    oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    v-on:blur="check_vat_length"
                                                    v-model="
                                                        model.billing.vat_number
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'billing.vat_number'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'billing.name'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="vat_length_too_short"
                                                >
                                                    Túl rövid adószámot adott
                                                    meg
                                                </div>
                                            </template>
                                        </div>
                                        <div class="mt-4">
                                            <label
                                                >Telefonszám (mobil)*
                                                <small>
                                                    (pl.: 304572346)</small
                                                ></label
                                            >
                                            <!--<div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text"
                                                        id="basic-addon1"
                                                        >06</span
                                                    >
                                                </div>
                                                <input
                                                    v-model="model.shipping.phone"
                                                    type="text"
                                                    class="form-control"
                                                    aria-describedby="basic-addon1"
                                                />
                                            </div>-->
                                            <VuePhoneNumberInput
                                                default-country-code="HU"
                                                id="billing-phone-input"
                                                @update="phone_data = $event"
                                                :no-validator-state="
                                                    !phone_length_ok
                                                "
                                                @phone-number-blur="
                                                    handle_phone_number
                                                "
                                                :translations="{
                                                    countrySelectorLabel:
                                                        'Ország kód',
                                                    countrySelectorError:
                                                        'Hibás kiválasztás',
                                                    phoneNumberLabel:
                                                        'Telefonszám',
                                                    example: 'Példa :'
                                                }"
                                                v-model="model.shipping.phone"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'shipping.phone'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'shipping.phone'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                            <div
                                                v-if="!phone_valid"
                                                class="invalid-feedback"
                                            >
                                                Túl kevés karaktert adott meg
                                                mobil telefonszámnak
                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <label
                                                >Vezetékes telefonszám:
                                            </label>
                                            <input
                                                type="number"
                                                name="phone_line"
                                                v-model="
                                                    model.shipping.phone_line
                                                "
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'shipping.phone_line'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'shipping.phone_line'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 h5 title-small mt-5">
                                        Számlázási adataim
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label
                                                >Ország*
                                                <small>
                                                    (pl.: Magyarország)</small
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                name="country"
                                                v-model="model.billing.country"
                                                class="form-control"
                                                :readonly="
                                                    !isCompany && isSameShipping
                                                "
                                                :disabled="
                                                    !isCompany && isSameShipping
                                                "
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.country'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.country'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label
                                                >Irányítószám*
                                                <small>
                                                    (pl.: 1111)</small
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                name="zip"
                                                v-model="model.billing.zip"
                                                class="form-control"
                                                v-on:keyup="getBillingCity"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.zip'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.zip'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label
                                                >Város*
                                                <small>
                                                    (pl.: Budapest)</small
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                name="city"
                                                v-model="model.billing.city"
                                                class="form-control"
                                                :readonly="
                                                    !isCompany &&
                                                        !model.billing.city &&
                                                        isSameShipping
                                                "
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.city'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.city'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label>Közterület*</label>
                                            <!--<input
                                                type="text"
                                                name="city"
                                                v-model="model.billing.street"
                                                class="form-control"
                                                v-if="
                                                    isCompany ||
                                                        (!isCompany &&
                                                            !isSameShipping)
                                                "
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.street'
                                                    )
                                                }"
                                            />-->
                                            <vue-single-select
                                                v-model="model.billing.street"
                                                :placeholder="
                                                    model.billing.street
                                                        ? model.billing.street
                                                        : '-'
                                                "
                                                :maxResults="500"
                                                :options="streetnames_billing"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.street'
                                                    )
                                                }"
                                            ></vue-single-select>
                                            <select
                                                name="street"
                                                v-model="model.billing.street"
                                                class="form-control hidden"
                                                v-on:change="getPostAdress"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.street'
                                                    )
                                                }"
                                            >
                                                <option :value="null">-</option>
                                                <template
                                                    v-for="(item,
                                                    index) in postAdress.streets"
                                                >
                                                    <option
                                                        v-bind:key="
                                                            'street-bill-' +
                                                                index
                                                        "
                                                        :value="
                                                            item.street_name
                                                        "
                                                        >{{
                                                            item.street_name
                                                        }}</option
                                                    >
                                                </template>
                                            </select>
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.street'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label>Közterület jellege*</label>
                                            <!--<input
                                                type="text"
                                                name="street"
                                                v-model="
                                                    model.billing.street_type
                                                "
                                                class="form-control"
                                                v-if="
                                                    isCompany ||
                                                        (!isCompany &&
                                                            !isSameShipping)
                                                "
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.street_type'
                                                    )
                                                }"
                                            />-->
                                            <select
                                                name="street"
                                                v-model="
                                                    model.billing.street_type
                                                "
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.street_type'
                                                    )
                                                }"
                                            >
                                                <option :value="null">-</option>
                                                <template
                                                    v-for="(item,
                                                    index) in postAdress.street_type"
                                                >
                                                    <option
                                                        v-bind:key="
                                                            'street-type-bill-' +
                                                                index
                                                        "
                                                        :value="
                                                            item.street_type
                                                        "
                                                        >{{
                                                            item.street_type
                                                        }}</option
                                                    >
                                                </template>
                                            </select>
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.street_type'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label
                                                >Házszám*
                                                <small> (pl.: 1)</small></label
                                            >
                                            <input
                                                type="text"
                                                name="street_number"
                                                v-model="
                                                    model.billing.street_number
                                                "
                                                :disabled="
                                                    disable_billig_helyrajzi
                                                "
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.street_number'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.street_number'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label
                                                >Emelet
                                                <small> (pl.: 3)</small></label
                                            >
                                            <input
                                                type="number"
                                                name="floor"
                                                v-model="model.billing.floor"
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.floor'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.floor'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label
                                                >Ajtó
                                                <small> (pl.: 5)</small></label
                                            >
                                            <input
                                                type="number"
                                                name="door"
                                                v-model="model.billing.door"
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.door'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.door'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-4">
                                            <label>Helyrajzi szám</label>
                                            <input
                                                type="number"
                                                name="helyrajzi_szam"
                                                v-model="
                                                    model.billing.helyrajzi_szam
                                                "
                                                class="form-control"
                                                v-bind:class="{
                                                    'is-invalid': hasErrorMessagesX(
                                                        'billing.helyrajzi_szam'
                                                    )
                                                }"
                                            />
                                            <div
                                                class="invalid-feedback"
                                                v-for="message in getErrorMessages(
                                                    'billing.helyrajzi_szam'
                                                )"
                                                v-bind:key="message"
                                            >
                                                {{ message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                    <div class="col-md-12">
                                        <div class="mt-4">
                                            <label
                                                class="d-block shipping-same-info"
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="usertype"
                                                    class="form-check-input transition"
                                                    :value="1"
                                                    v-model="isSameShipping"
                                                    v-on:change="changeSameShip"
                                                    checked
                                                />
                                                Szállítási címem megegyezik a
                                                számlázási címemmel.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="!isSameShipping">
                                    <div class="row">
                                        <hr class="col-12 m-2 mt-5" />
                                    </div>
                                    <div class="row">
                                        <div class="col-12 h5 title-small mt-5">
                                            Szállítási adataim
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label>Név*</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    v-model="
                                                        model.shipping.name
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.name'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.name'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Ország*
                                                    <small>
                                                        (pl.:
                                                        Magyarország)</small
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    name="shipping_country"
                                                    v-model="
                                                        model.shipping.country
                                                    "
                                                    class="form-control"
                                                    :readonly="'readonly'"
                                                    :disabled="'disabled'"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.country'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.country'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Irányítószám*
                                                    <small>
                                                        (pl.: 1111)</small
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    name="shipping_zip"
                                                    v-model="model.shipping.zip"
                                                    class="form-control"
                                                    v-on:keyup="getShippingCity"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.zip'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.zip'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Város*
                                                    <small>
                                                        (pl.: Budapest)</small
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    name="shipping_city"
                                                    v-model="
                                                        model.shipping.city
                                                    "
                                                    class="form-control"
                                                    :readonly="
                                                        !model.shipping.city
                                                    "
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.city'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.city'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label>Közterület*</label>
                                                <vue-single-select
                                                    required="true"
                                                    v-model="
                                                        model.shipping.street
                                                    "
                                                    :placeholder="
                                                        model.shipping.street
                                                            ? model.shipping
                                                                  .street
                                                            : '-'
                                                    "
                                                    :maxResults="500"
                                                    :options="
                                                        streetnames_shipping
                                                    "
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'billing.street'
                                                        )
                                                    }"
                                                ></vue-single-select>
                                                <select
                                                    name="street"
                                                    v-model="
                                                        model.shipping.street
                                                    "
                                                    class="form-control hidden"
                                                    v-on:change="
                                                        getPostAdressShip
                                                    "
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.street'
                                                        )
                                                    }"
                                                >
                                                    <option :value="null"
                                                        >-</option
                                                    >
                                                    <template
                                                        v-for="(item,
                                                        index) in postAdressShip.streets"
                                                    >
                                                        <option
                                                            v-bind:key="
                                                                'street-ship-' +
                                                                    index
                                                            "
                                                            :value="
                                                                item.street_name
                                                            "
                                                            >{{
                                                                item.street_name
                                                            }}</option
                                                        >
                                                    </template>
                                                </select>
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.street'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Közterület jellege*</label
                                                >
                                                <select
                                                    name="street"
                                                    v-model="
                                                        model.shipping
                                                            .street_type
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.street_type'
                                                        )
                                                    }"
                                                >
                                                    <option :value="null"
                                                        >-</option
                                                    >
                                                    <template
                                                        v-for="(item,
                                                        index) in postAdressShip.street_type"
                                                    >
                                                        <option
                                                            v-bind:key="
                                                                'street-type-ship-' +
                                                                    index
                                                            "
                                                            :value="
                                                                item.street_type
                                                            "
                                                            >{{
                                                                item.street_type
                                                            }}</option
                                                        >
                                                    </template>
                                                </select>
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.street_type'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Házszám*
                                                    <small>
                                                        (pl.: 1)</small
                                                    ></label
                                                >
                                                <input
                                                    type="text"
                                                    name="street_number"
                                                    v-model="
                                                        model.shipping
                                                            .street_number
                                                    "
                                                    :disabled="
                                                        disable_shipping_helyrajzi
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.street_number'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.street_number'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Emelet
                                                    <small>
                                                        (pl.: 3)</small
                                                    ></label
                                                >
                                                <input
                                                    type="number"
                                                    name="floor"
                                                    v-model="
                                                        model.shipping.floor
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.floor'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.floor'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label
                                                    >Ajtó
                                                    <small>
                                                        (pl.: 5)</small
                                                    ></label
                                                >
                                                <input
                                                    type="number"
                                                    name="door"
                                                    v-model="
                                                        model.shipping.door
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.door'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.door'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mt-4">
                                                <label>Helyrajzi szám</label>
                                                <input
                                                    type="number"
                                                    name="helyrajzi_szam"
                                                    v-model="
                                                        model.shipping
                                                            .helyrajzi_szam
                                                    "
                                                    class="form-control"
                                                    v-bind:class="{
                                                        'is-invalid': hasErrorMessagesX(
                                                            'shipping.helyrajzi_szam'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-for="message in getErrorMessages(
                                                        'shipping.helyrajzi_szam'
                                                    )"
                                                    v-bind:key="message"
                                                >
                                                    {{ message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="row">
                                    <div class="col-md-8">
                                        <router-link
                                            :to="'/'"
                                            class="d-block continue-shop-btn transition"
                                            >Vásárlás folytatása</router-link
                                        >
                                    </div>
                                    <div class="col-md-4 text-end">
                                        <button
                                            type="submit"
                                            class="btn btn-primary w-100 mb-2"
                                        >
                                            Tovább
                                        </button>
                                        <p class="text-mini text-grey">
                                            A *-al jelölt mezők kitöltése
                                            kötelező
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProfileData from "@/views/ProfileData";
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import VueSingleSelect from "@/components/VueSingleSelect";
import CheckoutBreadcrumbs from "@/components/CheckoutBreadcrumbs";
import VuePhoneNumberInput from "vue-phone-number-input";
import store from "@/core/services/store";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    name: "CheckoutBilling",
    components: {
        CheckoutBreadcrumbs,
        BreadCrumbs,
        VuePhoneNumberInput,
        VueSingleSelect
    },
    extends: ProfileData,
    computed: {},
    data() {
        return {
            phone_length_ok: false,
            phone_valid: true,
            phone_data: null,
            tax_number_prev: null,
            postAdress: [],
            postAdressShip: [],
            customer_with_vat: false,
            streetnames_billing: [],
            streetnames_shipping: [],
            disable_billig_helyrajzi: false,
            disable_shipping_helyrajzi: false,
            vat_length_too_short: false
        };
    },
    watch: {
        $route: {
            handler: function() {
                if ($cookies.get("billing")) {
                    this.model = $cookies.get("billing");
                }
            },
            deep: true
        },
        postAdress: {
            handler: function() {
                if (
                    this.postAdress.streets &&
                    this.postAdress.streets.length > 0
                ) {
                    this.streetnames_billing = [];
                    for (let i = 0; i < this.postAdress.streets.length; i++) {
                        this.streetnames_billing.push(
                            this.postAdress.streets[i].street_name
                        );
                    }
                }
            },
            deep: true
        },
        postAdressShip: {
            handler: function() {
                if (
                    this.postAdressShip.streets &&
                    this.postAdressShip.streets.length > 0
                ) {
                    this.streetnames_shipping = [];
                    for (
                        let i = 0;
                        i < this.postAdressShip.streets.length;
                        i++
                    ) {
                        this.streetnames_shipping.push(
                            this.postAdressShip.streets[i].street_name
                        );
                    }
                }
            },
            deep: true
        },
        model: {
            handler: function() {
                this.getPostAdress();
                this.getPostAdressShip();
                if (
                    this.model &&
                    this.model.shipping &&
                    this.model.shipping.phone &&
                    this.model.shipping.phone.length === 1 &&
                    this.model.shipping.phone === "0"
                ) {
                    this.model.shipping.phone = "";
                }

                if (
                    this.model &&
                    this.model.shipping &&
                    this.model.shipping.phone &&
                    this.model.shipping.phone.length === 9
                ) {
                    this.phone_length_ok = true;
                } else {
                    this.phone_length_ok = false;
                }

                if (
                    this.model &&
                    this.model.shipping &&
                    this.model.shipping.phone &&
                    this.model.shipping.phone.length > 9
                ) {
                    this.model.shipping.phone = this.model.shipping.phone.slice(
                        0,
                        -1
                    );
                }

                //Ha van helyrajzi szám, ne legyen házszám
                if (
                    this.model &&
                    this.model.billing &&
                    this.model.billing.helyrajzi_szam &&
                    this.model.billing.helyrajzi_szam.length > 0
                ) {
                    this.disable_billig_helyrajzi = true;
                    this.model.billing.street_number = "-";
                } else {
                    this.disable_billig_helyrajzi = false;
                    if (this.model.billing.street_number === "-") {
                        this.model.billing.street_number = "";
                    }
                }

                if (
                    this.model &&
                    this.model.shipping &&
                    this.model.shipping.helyrajzi_szam &&
                    this.model.shipping.helyrajzi_szam.length > 0
                ) {
                    this.disable_shipping_helyrajzi = true;
                    this.model.shipping.street_number = "-";
                } else {
                    this.disable_shipping_helyrajzi = false;

                    if (this.model.shipping.street_number === "-") {
                        this.model.shipping.street_number = "";
                    }
                }

                //Adószámba ne lehessen '-'t írni
                if (
                    typeof this.model.billing.vat_number !== "undefined" &&
                    this.model.billing.vat_number &&
                    this.model.billing.vat_number !== null &&
                    this.model.billing.vat_number.length
                ) {
                    this.vat_length_too_short = false;
                    if (
                        this.model.billing.vat_number[
                            this.model.billing.vat_number.length - 1
                        ] === "-"
                    ) {
                        this.model.billing.vat_number = this.model.billing.vat_number.slice(
                            0,
                            -1
                        );
                    }
                }

                //Adószám formátum kiegészítés közőjellel 8 karakternél
                if (
                    typeof this.model.billing.vat_number !== "undefined" &&
                    this.model.billing.vat_number &&
                    this.model.billing.vat_number !== null &&
                    this.model.billing.vat_number.length &&
                    this.model.billing.vat_number.length === 9 &&
                    this.model.billing.vat_number.length >
                        this.tax_number_prev.length
                ) {
                    let lastChar = this.model.billing.vat_number.substr(
                        this.model.billing.vat_number.length - 1
                    );
                    let tax_number_cutted_last = this.model.billing.vat_number.slice(
                        0,
                        -1
                    );
                    this.model.billing.vat_number =
                        tax_number_cutted_last + "-" + lastChar;
                }
                //Adószám formátum kiegészítés közőjellel 11 karakternél
                if (
                    this.model.billing.vat_number &&
                    typeof this.model.billing.vat_number !== "undefined" &&
                    this.model.billing.vat_number.length === 11 &&
                    this.model.billing.vat_number.length >
                        this.tax_number_prev.length
                ) {
                    let lastChar = this.model.billing.vat_number.substr(
                        this.model.billing.vat_number.length - 1
                    );
                    let tax_number_cutted_last = this.model.billing.vat_number.slice(
                        0,
                        -1
                    );
                    this.model.billing.vat_number =
                        tax_number_cutted_last + "-" + lastChar;
                }

                if (
                    typeof this.model.billing.vat_number !== "undefined" &&
                    this.model.billing.vat_number &&
                    this.model.billing.vat_number.length === 14
                ) {
                    this.model.billing.vat_number = this.model.billing.vat_number.slice(
                        0,
                        -1
                    );
                } else {
                    this.tax_number_prev = this.model.billing.vat_number;
                }
                let helper = JSON.parse(JSON.stringify(this.model));
                helper["company"] = this.isCompany;
                helper["same_shipping"] = this.isSameShipping;
                $cookies.set("billing", JSON.stringify(helper), 86400);

                if (this.isSameShipping) {
                    this.model.shipping.name = this.model.billing.name;
                    this.model.shipping.country = this.model.billing.country;
                    this.model.shipping.city = this.model.billing.city;
                    this.model.shipping.door = this.model.billing.door;
                    this.model.shipping.floor = this.model.billing.floor;
                    this.model.shipping.street = this.model.billing.street;
                    this.model.shipping.street_number = this.model.billing.street_number;
                    this.model.shipping.street_type = this.model.billing.street_type;
                    this.model.shipping.zip = this.model.billing.zip;
                    this.model.shipping.helyrajzi_szam = this.model.billing.helyrajzi_szam;
                }
            },
            deep: true
        }
    },
    mounted() {
        if (document.getElementById("pages") !== null) {
            $("html, body").animate(
                {
                    scrollTop: $("#pages").offset().top - 150
                },
                1000
            );
        }
    },
    beforeMount() {
        if ($cookies.get("id_token") && $cookies.get("user")) {
            this.getUserData();
        }
        if ($cookies.get("billing")) {
            this.model = $cookies.get("billing");
        }

        this.model.billing.country = "Magyarország";
        this.model.shipping.country = "Magyarország";
        this.getPostAdress();
        this.getPostAdressShip();
    },
    methods: {
        check_vat_length: function() {
            if (
                typeof this.model.billing.vat_number !== "undefined" &&
                this.model.billing.vat_number &&
                this.model.billing.vat_number !== null &&
                this.model.billing.vat_number.length &&
                this.model.billing.vat_number.length < 13
            ) {
                this.model.billing.vat_number = "";
                this.vat_length_too_short = true;
            }
        },
        handle_phone_number: function() {
            if (
                this.model &&
                this.model.shipping &&
                this.model.shipping.phone &&
                this.model.shipping.phone.length < 9 &&
                this.phone_data["countryCode"] === "HU"
            ) {
                this.phone_valid = false;
                this.model.shipping.phone = null;
            } else {
                if (
                    this.model &&
                    this.model.shipping &&
                    this.model.shipping.phone &&
                    typeof this.model.shipping.phone !== "undefined" &&
                    this.model.shipping.phone !== null &&
                    this.model.shipping.phone !== ""
                ) {
                    console.log(this.model.shipping.phone);
                    this.phone_valid = true;
                }
            }
        },
        getErrorMessages: function(name) {
            const errors = store.state.defaultStore.errors;
            for (const er in errors) {
                if (er === name) {
                    return errors[er];
                }
            }
            return "";
        },
        hasErrorMessagesX: function(val) {
            const errors = store.state.defaultStore.errors;
            for (const er in errors) {
                if (er === val) {
                    return true;
                }
            }

            return false;
        },
        user_without_vat() {
            this.isCompany = false;
            this.customer_with_vat = false;
        },
        user_with_vat() {
            this.isCompany = false;
            this.customer_with_vat = true;
        },
        is_company() {
            this.isCompany = true;
            this.customer_with_vat = false;
        },
        getPostAdress() {
            if (this.model.billing.street && this.model.billing.zip) {
                ApiService.query(
                    `${this.url}/streets/${this.model.billing.zip}/${this.model.billing.street}`
                ).then(resp => {
                    this.postAdress = resp.data;
                });
            } else if (this.model.billing.zip) {
                ApiService.query(
                    `${this.url}/streets/${this.model.billing.zip}`
                ).then(resp => {
                    this.postAdress = resp.data;
                });
            } else {
                ApiService.query(`${this.url}/streets`).then(resp => {
                    this.postAdress = resp.data;
                });
            }
        },
        getPostAdressShip() {
            if (this.model.shipping.street && this.model.shipping.zip) {
                ApiService.query(
                    `${this.url}/streets/${this.model.shipping.zip}/${this.model.shipping.street}`
                ).then(resp => {
                    this.postAdressShip = resp.data;
                });
            } else if (this.model.shipping.zip) {
                ApiService.query(
                    `${this.url}/streets/${this.model.shipping.zip}`
                ).then(resp => {
                    this.postAdressShip = resp.data;
                });
            } else {
                ApiService.query(`${this.url}/streets`).then(resp => {
                    this.postAdressShip = resp.data;
                });
            }
        },
        getBillingCity() {
            ApiService.query(
                `${this.url}/city-by-zip/${this.model.billing.zip}`
            ).then(resp => {
                if (resp.data.city !== null) {
                    this.model.billing.city = resp.data.city;
                    ApiService.query(
                        `${this.url}/streets/${this.model.billing.zip}`
                    ).then(resp => {
                        this.postAdress = resp.data;
                    });
                }
            });
        },
        getShippingCity() {
            ApiService.query(
                `${this.url}/city-by-zip/${this.model.shipping.zip}`
            ).then(resp => {
                if (resp.data.city !== null) {
                    this.model.shipping.city = resp.data.city;
                    ApiService.query(
                        `${this.url}/streets/${this.model.shipping.zip}`
                    ).then(resp => {
                        this.postAdressShip = resp.data;
                    });
                }
            });
        },
        validateData() {
            this.model.billing.country = "Magyarország";
            this.model.shipping.country = "Magyarország";
            if (
                this.model &&
                this.model.shipping &&
                this.model.shipping.phone &&
                this.model.shipping.phone.length < 9 &&
                this.phone_data["countryCode"] === "HU"
            ) {
                this.phone_valid = false;
                this.model.shipping.phone = "";
            }
            if (
                this.model.shipping.phone ||
                (typeof this.model.shipping.phone !== "undefined" &&
                    this.model.shipping.phone !== null &&
                    this.model.shipping.phone !== "")
            ) {
                this.model.shipping.phone_ext =
                    "+" + this.phone_data["countryCallingCode"];
            }
            if (this.customer_with_vat === true) {
                this.isCompany = true;
            }
            const self = this;
            let helper = JSON.parse(JSON.stringify(this.model));
            helper["company"] = this.isCompany;
            helper["same_shipping"] = this.isSameShipping;

            ApiService.post(`${this.url}/validate`, helper).then(res => {
                console.log(res);
                $cookies.set("billing", JSON.stringify(helper), 86400);
                self.$router.push({ name: "CheckoutShipping" });
            });
        }
    }
};
</script>

<style scoped></style>
