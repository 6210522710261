var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('BreadCrumbs',{attrs:{"elements":[
            { link: 'Cart', name: 'Kosár' },
            { link: 'CheckoutBilling', name: 'Számlázási adatok' },
            { link: 'CheckoutShipping', name: 'Szállítási mód' },
            { link: 'CheckoutPayment', name: 'Fizetési mód' }
        ]}}),_c('section',{staticClass:"listSection listing"},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"pages"}},[_vm._m(0),_c('CheckoutBreadcrumbs',{attrs:{"step":4}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.setPayment.apply(null, arguments)}}},[_c('div',{staticClass:"my-4 radio-corrector"},[_vm._l((_vm.model),function(item,index){return [_c('label',{key:'payment-' + index,staticClass:"d-block mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.payment),expression:"form.payment"}],attrs:{"type":"radio","name":"payment"},domProps:{"value":item.id,"checked":index === 0,"checked":_vm._q(_vm.form.payment,item.id)},on:{"change":function($event){return _vm.$set(_vm.form, "payment", item.id)}}}),_vm._v(" "+_vm._s(item.name)+" "),(
                                                parseInt(item.price) !== 0
                                            )?void 0:_vm._e()],2)]})],2),_c('div',{staticClass:"my-4"},[_c('label',[_vm._v("Kuponkód megadása")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.coupon),expression:"form.coupon"}],staticClass:"form-control",attrs:{"type":"text","name":"coupon"},domProps:{"value":(_vm.form.coupon)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "coupon", $event.target.value)}}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('router-link',{staticClass:"d-block continue-shop-btn transition",attrs:{"to":'/'}},[_vm._v("Vásárlás folytatása ")])],1),_vm._m(1)])])])])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Szállítási adatok")]),_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4 text-end"},[_c('button',{staticClass:"btn btn-primary w-100 mb-2",attrs:{"type":"submit"}},[_vm._v(" Tovább ")])])}]

export { render, staticRenderFns }