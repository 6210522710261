<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' },
                { link: 'CheckoutShipping', name: 'Szállítási mód' },
                { link: 'CheckoutPayment', name: 'Fizetési mód' },
                { link: 'CheckoutFinish', name: 'Rendelés áttekintése' }
            ]"
        />
        <section class="listSection listing">
            <div class="container">
                <div id="pages">
                    <div class="headline">
                        <h1>Szállítási adatok</h1>
                        <hr />
                    </div>
                    <CheckoutBreadcrumbs :step="5" />
                    <div class="row">
                        <div class="col-12">
                            <div
                                class="row align-items-center border-bottom pb-3 d-none d-md-flex"
                            >
                                <div class="col-md-6">
                                    <strong class="text-uppercase"
                                        >Termék neve</strong
                                    >
                                </div>
                                <div class="col-md-2">
                                    <strong class="text-uppercase"
                                        >Ár/Db</strong
                                    >
                                </div>
                                <div class="col-md-2">
                                    <strong class="text-uppercase"
                                        >Mennyiség</strong
                                    >
                                </div>
                                <div class="col-md-2">
                                    <strong class="text-uppercase"
                                        >Részösszeg</strong
                                    >
                                </div>
                            </div>
                            <template v-for="(item, index) in cart.items">
                                <div
                                    class="row align-items-center border-bottom py-3"
                                    v-bind:key="'cart-' + index"
                                >
                                    <div class="col-md-6">
                                        <strong class="text-uppercase"
                                            >{{ item.product.cicsopnev }} <br />
                                            {{ item.product.cikknev }} <br />
                                            {{ item.product.m1nev }}</strong
                                        >
                                    </div>
                                    <div class="col-md-2">
                                        {{
                                            item.product
                                                .calculated_special_price
                                        }}
                                        <template
                                            v-if="
                                                !item.product.calculated_special_price.includes(
                                                    'Ft'
                                                )
                                            "
                                            >Ft</template
                                        >
                                    </div>
                                    <div class="col-md-2">
                                        <div class="d-flex align-items-center">
                                            <input
                                                type="number"
                                                readonly
                                                v-model="item.quantity"
                                                min="1"
                                                :max="item.product.tat"
                                                class="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        {{
                                            parseInt(
                                                item.product.calculated_special_price.replace(
                                                    " ",
                                                    ""
                                                )
                                            ) * item.quantity
                                        }}
                                        Ft
                                    </div>
                                </div>
                            </template>
                        </div>
                        <section>
                            <div class="container">
                                <div class="row my-4">
                                    <div class="col-xl-9">
                                        <label class="d-block"
                                            >Megjegyzés (max. 45
                                            karakter)</label
                                        >
                                        <textarea
                                            rows="5"
                                            maxlength="45"
                                            v-model="cart.notes"
                                        />
                                    </div>
                                    <div
                                        class="col-xl-3"
                                        v-if="cart && cart.cart_total"
                                    >
                                        <div class="row">
                                            <div class="col-6">
                                                Abroncs végösszeg:
                                            </div>
                                            <div class="col-6 text-end">
                                                {{ cart.cart_total.total_f }}
                                                Ft
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                Szállítási költség:
                                            </div>
                                            <div class="col-6 text-end">
                                                {{ cart.cart_total.shipping_f }}
                                                Ft
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            v-if="
                                                cart.cart_total.total_payment >
                                                    0
                                            "
                                        >
                                            <div class="col-6">
                                                Utánvét összege:
                                            </div>
                                            <div class="col-6 text-end">
                                                {{
                                                    cart.cart_total
                                                        .total_payment_f
                                                }}
                                                Ft
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <strong class="text-orange"
                                                    >Végösszeg:</strong
                                                >
                                            </div>
                                            <div class="col-6 text-end">
                                                <strong class="text-orange"
                                                    >{{
                                                        cart.cart_total
                                                            .total_with_shipping_f
                                                    }}
                                                    Ft</strong
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <label class="d-block mb-2 aszf">
                                            <input
                                                type="checkbox"
                                                name="aszf"
                                                value="1"
                                                v-model="aszf"
                                            />
                                            Elfogadom az
                                            <a
                                                href="/adatvedelmi-tajekoztato"
                                                target="_blank"
                                                style="    text-decoration: underline !important;"
                                                >Adatvédelmi nyilatkozatot</a
                                            >
                                        </label>
                                        <!--<p>
                                            Tudomásul veszem, hogy a(z) Cégnév
                                            (Telephely) adatkezelő által a(z)
                                            https://kellagumi.hu felhasználói
                                            adatbázisában tárolt alábbi
                                            személyes adataim átadásra kerülnek
                                            az OTP Mobil Kft., mint
                                            adatfeldolgozzó részére. Az
                                            adatkezelő által továbbított adatok
                                            köré az alábbi: név, email cím,
                                            számlázási adatok. Az adatfeldolgozó
                                            által végzett adatledolgozási
                                            tevékenység jellege és célja a
                                            SimplePay Adatkezelési
                                            tájékoztatóban. az alábbi linken
                                            tekinthető meg:
                                            http://simplepay.hu/vasarlo-aff.
                                        </p>-->
                                    </div>
                                </div>
                                <div class="row my-4">
                                    <div class="col-xl-3 offset-xl-9">
                                        <button
                                            v-on:click="doOrder"
                                            type="submit"
                                            class="btn btn-primary w-100 my-4"
                                            v-bind:disabled="orderDisabled"
                                        >
                                            Megrendelem
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CheckoutBreadcrumbs from "@/components/CheckoutBreadcrumbs";

export default {
    name: "CheckoutFinish",
    components: { CheckoutBreadcrumbs, BreadCrumbs },
    data() {
        return {
            cart: {},
            model: {
                billing: {},
                shipping: {}
            },
            shipping: {},
            payment: {},
            aszf: false,
            orderDisabled: false
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    beforeMount() {
        if (
            !$cookies.get("billing") ||
            !$cookies.get("payment") ||
            !$cookies.get("shipping")
        ) {
            this.$router.push({ name: "Cart" });
        }
        this.getCart();
        this.model = $cookies.get("billing");
        this.shipping = $cookies.get("shipping");
        this.payment = $cookies.get("payment");
    },
    methods: {
        getCart() {
            const cartUuid = $cookies.get("cart_uuid");
            this.shipping = $cookies.get("shipping");
            ApiService.query(
                `${this.url}/cart?uuid=${cartUuid}&shipping=${this.shipping}`
            ).then(response => {
                $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                this.cart = response.data.data;
            });
        },
        doOrder() {
            const self = this;
            self.orderDisabled = true;
            ApiService.post(`${self.url}/order`, {
                cart: self.cart,
                personal: self.model,
                shipping: self.shipping,
                payment: self.payment,
                aszf: self.aszf
            })
                .then(response => {
                    if (response.data.redirect) {
                        document.location.href = response.data.redirect;
                    } else {
                        self.$router.push({ name: "CheckoutSuccess" });
                    }
                })
                .finally(() => {
                    self.orderDisabled = false;
                });
        }
    }
};
</script>

<style scoped></style>
